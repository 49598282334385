<template>
  <qlHeader :bgColor="'rgba(0, 0, 0, 0.1)'" class="home_header">
    <div slot="logo" class="logo">
      <img :src="logo" />
      <p>{{ platformName }}</p>
    </div>
    <p slot="list" class="header_school" v-show="schoolName">{{ schoolName }}</p>
  </qlHeader>
</template>

<script>
import { mapState } from 'vuex';
import logo from '@images/home/home_logo.png';
import qlHeader from '@/components/header';

export default {
  components: {
    qlHeader,
  },
  props: {
    schoolName: {
      type: String,
    },
  },
  data() {
    return {
      logo,
    };
  },
  computed: {
    ...mapState({
      platformName: (state) => state.common.platformName,
    }),
  },
};
</script>

<style lang="scss" scoped>
.header_school {
  @apply T5 text-A10;
  padding-right: 16px;
  border-right: 1px solid #555555;
  margin-right: 16px;

  .logo {
    @apply T3B text-A10;
    letter-spacing: 1px;
    align-items: center;
    display: flex;

    img {
      margin-right: 16px;
    }
  }
}
</style>
