export const CLASSROOM = 'ketang'; // 青小鹿课堂
export const CLASS_ANALYSIS = 'zhihuiketangfenxi'; // 智慧课堂分析（已删除）
export const BIG_DATA = 'databoardScreen'; // 教学数据大屏
export const TEACHING_REPORT = 'databoardStat'; // 教学统计报告
export const CLASS_BOARD = 'qingluguanjia'; // 青小鹿管家
export const EXERCISE = 'zhihuizuoye'; // 青小鹿测练EXERCISE
export const TEACHING_HOMEWORK = 'jiaofuzuoye'; // 教辅作业
export const RESOURCES = 'jiaoxueziyuan'; // 教学资源
export const MANAGEMENT = 'jichushuju'; // 后台管理
export const GROUP = 'fenzuguanli'; // 分组管理

export const menuConfig = {
  [CLASSROOM]: {
    title: '智慧课堂',
    subTitle: 'INTELLIGENT CLASSROOM',
    imgClass: 'IntelligentClassroom',
  },
  [CLASS_ANALYSIS]: {
    title: '智慧课堂分析',
    subTitle: 'ACADEMIC ANALYSIS',
    imgClass: 'AcademicAnalysis',
  },
  [BIG_DATA]: {
    title: '教学数据大屏',
    subTitle: 'TEACHING DATA',
    imgClass: 'IntelligentBigData',
  },
  [TEACHING_REPORT]: {
    title: '教学统计报告',
    subTitle: 'TEACHING REPORT',
    imgClass: 'TeachingReport',
  },
  [CLASS_BOARD]: {
    title: '青小鹿管家',
    subTitle: 'INTELLIGENT CLASS BOARD',
    imgClass: 'IntelligentClassBoard',
  },
  [EXERCISE]: {
    title: '日常作业',
    subTitle: 'DAILY HOMEWORK',
    imgClass: 'IntelligentTest',
  },
  [TEACHING_HOMEWORK]: {
    title: '教辅作业',
    subTitle: 'TA HOMEWORK',
    imgClass: 'TeachingTest',
  },
  [RESOURCES]: {
    title: '教学资源',
    subTitle: 'RESOURCE',
    imgClass: 'Resource',
  },
  [MANAGEMENT]: {
    title: '后台管理',
    subTitle: 'MANAGEMENT',
    imgClass: 'Management',
  },
  [GROUP]: {
    title: '分组管理',
    subTitle: 'GROUP',
    imgClass: 'IntelligentGroup',
  },
};
