<template>
  <ul class="homeMenu" :style="menuStyle">
    <li class="list_item" v-for="(item, index) in menu" :key="index" :style="menuItemStyle" @click="handleClick(item)">
      <p class="list_item-img" :class="[item.imgClass]"></p>
      <p class="list_item-title">{{ item.title }}</p>
      <p class="list_item-subtitle">{{ item.subTitle }}</p>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'HomeMenu',
  props: {
    menu: {
      type: Array,
    },
  },
  computed: {
    menuStyle() {
      if (this.menu?.length) {
        const menuLength = this.menu.length;
        if (menuLength <= 4) {
          return { width: menuLength * 300 + 'px' };
        } else if (menuLength === 6) {
          return { width: '1440px' };
        } else if (menuLength > 10) {
          return { width: '1440px', 'justify-content': 'flex-start' };
        } else if (menuLength > 6) {
          return { 'justify-content': 'flex-start' };
        }
      }
      return {};
    },

    menuItemStyle() {
      return this.menu && this.menu.length > 6 ? { margin: '0 10px' } : {};
    },
  },
  methods: {
    handleClick(item) {
      this.$emit('handleClick', item);
    },
  },
};
</script>

<style lang="scss" scoped>
$menuImage: 'IntelligentBigData' 'TeachingReport' 'IntelligentClassBoard' 'IntelligentClassroom' 'IntelligentTest'
  'Management' 'IntelligentGroup' 'Resource' 'TeachingTest';

.homeMenu {
  width: 1200px;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  transform: translate(-50%, -50%);
  margin-top: -60px;

  .list_item {
    @apply text-A10;
    width: 220px;
    height: 260px;
    padding-bottom: 10px;
    text-align: center;
    line-height: 20px;
    cursor: pointer;

    &-img {
      width: 220px;
      height: 200px;
      background-repeat: no-repeat;
      background-color: transparent;
      background-size: contain;
    }

    &-title {
      @apply text-22 font-bold;
      opacity: 0.7;
    }

    &-subtitle {
      @apply T6;
      opacity: 0.15;
      margin: 11px 0;
    }

    @each $i in $menuImage {
      .#{$i} {
        background-image: url('~@images/home/home_#{$i}_1.png');
      }
    }

    &:hover,
    &:active {
      .list_item-title {
        opacity: 1;
      }
      .list_item-subtitle {
        opacity: 0.2;
      }

      @each $i in $menuImage {
        .#{$i} {
          background-image: url('~@images/home/home_#{$i}_2.png');
        }
      }
    }
  }
}
</style>
