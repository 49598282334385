<template>
  <el-dialog custom-class="ql-dialog homeUpgrade" title="升年级提示" :visible.sync="visible">
    <div>
      <h3 class="homeUpgrade_title">
        <span class="homeUpgrade_time">{{ tipTime }}</span
        >，本校将进行升年级操作！
      </h3>
      <p class="homeUpgrade_tip">
        以下选中班级的升级方式为不分班升级，未选中班级的升级方式为分班升级；您可以再次调整升级方式；
      </p>
      <div class="flex mg-t-24" v-for="item in list" :key="item.periodId">
        <span class="homeUpgrade_key">{{ item.periodName }}：</span>
        <el-checkbox-group v-model="item.checkList">
          <el-checkbox v-for="option in item.gradeTypeList" :label="option.gradeTypeId" :key="option.gradeTypeId">{{
            option.gradeTypeName
          }}</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <span slot="footer">
      <el-button type="text" size="small" @click="handleReset">重设提示时间</el-button>
      <el-button type="primary" style="width: 144px" @click="handleClick">系统默认升级</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Button, Dialog, Checkbox, CheckboxGroup } from 'element-ui';
export default {
  name: 'HomeUpgrade',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    list: Array,
    showTipTime: Object,
  },
  data() {
    return {};
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    tipTime() {
      if (this.showTipTime && this.showTipTime.month) {
        return `${this.showTipTime.month}月${this.showTipTime.day}日`;
      }
      return '';
    },
  },
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
  },
  created() {},
  mounted() {},
  methods: {
    handleReset() {
      this.$router.push({
        // name: 'basic-upgrade',
        path: '/back-stage/basic-data/upgrade',
      });
    },

    handleClick() {
      this.$emit('upgrade');
    },
  },
};
</script>
<style lang="scss">
.homeUpgrade {
  .el-dialog__body {
    padding: 32px 40px;
  }
}
</style>
<style lang="scss" scoped>
.homeUpgrade_key {
  width: 42px;
  flex-shrink: 0;
}

.homeUpgrade_title {
  @apply text-F1 T3B;

  .homeUpgrade_time {
    color: #ffa51e;
  }
}

.homeUpgrade_tip {
  font-weight: 400;
  color: #6d7171;
  line-height: 24px;
  margin: 8px 0;
}
</style>
